<script>
	import {
		Pie
	} from 'vue-chartjs';

	export default {
		name: 'PieChart',
		extends: Pie,
		components: {
			'pie-chart': Pie
		},
		data() {
			return {
				datacollection: null
			};
		},
		methods: {
			renderPieChart() {
				const options = this.options;
				if (typeof options.responsive === 'undefined') options.responsive = true;
				if (typeof options.maintainAspectRatio === 'undefined') options.maintainAspectRatio = true;
				this.renderChart(this.cData, options);
			}
		},
		computed: {
			cData() {
				return this.chartData;
			},
			reactiveHeight() {
				return typeof this.options.height !== 'undefined' ? this.options.height : '100%';
			},
			reactiveWidth() {
				return typeof this.options.width !== 'undefined' ? this.options.width : '100%';
			}
		},
		mounted() {
			this.renderPieChart();
		},
		watch: {
			cData() {
				this.$data._chart.destroy();
				this.renderPieChart();
			}
		},
		beforeDestroy() {
			this.$data._chart.destroy();
		},
		props: {
			chartData: {
				type: Object,
				required: true
			},
			options: {
				type: Object,
				required: false
			}
		}
	};
</script>